import React from 'react'
import { Grid } from 'semantic-ui-react'

const siteLogo = () => (
    <Grid centered>
        <div className="logo-style">
            <div className="vis-logo-top vis-logo-top-frontpage w-40-l w-90 center relative z-2">  
                <svg className="vis-sprite">
                    <title>VIS logo</title>
                    <desc>Logo for VIS</desc>

                    <svg id="sprite-logo_topp" viewBox="0 0 555 144">
                        <title>logo_topp</title>
                        <g fill="#eb0045">
                            <path d="M276.9 107.8h5.1v35.7h-5.1z"></path>
                            <path d="M0 0v114.4h243.1l11.1 29.1h3.6l14-35.7h-5.3L256 134.7l-9.6-25.4H5.1V5.1h544.8v104.2H308.1c-1.9-1.1-4.4-2.1-7.5-2.1-6.2 0-10.8 4.3-10.8 9.5 0 3.7 2 6.2 4.5 7.9 4.7 3.1 12.3 3.7 12.3 9.2 0 3.2-2.9 5.4-6.2 5.4-3.7 0-6.6-2-8.7-4.6l-3.5 3.5c3.2 3.8 7.6 6 12.5 6 6.2 0 11.1-4.1 11.1-10.4 0-4.1-2.2-6.6-4.6-8.2-4.6-3.1-12.2-4-12.2-8.6 0-3.1 3-4.8 5.9-4.8 2 0 3.8.5 5.5 1.7l.9.6H555V0H0z">
                            </path>
                        </g>
                    </svg>
                </svg>
            </div>
        </div>
    </Grid>
)

export default siteLogo
