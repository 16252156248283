import React from "react";
import { Button, Header, Icon, Modal } from "semantic-ui-react";
import { saveProject } from "../functions/project-card-functions";
import { useAppState } from "../state/useAppState";
import {
  newBudgetLine,
  canUserApprove,
  sortedBudgetLines,
} from "./budget-functions";
import BudgetLine from "./BudgetLine.jsx";

const BudgetModal = (props) => {
  const { state, dispatcher } = useAppState();

  const userInfo = props.userInfo;
  const projectId = state.projectForEdit.id;

  const approversERP = [
    "07a51270-9106-4bc8-ad8c-703380a4e84b",
    "7f5ffcdf-eb70-453c-af5b-a10d1096f0b5",
  ];

  const ttoApprovers = [
    "08b4fee4-8c14-4bb7-b653-b70578a11807",
    "07a51270-9106-4bc8-ad8c-703380a4e84b",
  ];

  const innovationApprovers = [
    "71c8cce2-2bd7-4fd5-974b-0d543ea217a5",
    "8d4bb7c7-ecdb-46d8-82d7-9256b620146a",
  ];

  const merkantileApprovers = [
    "e7821a33-4027-4e82-b76b-097b2041c96d",
    "7f5ffcdf-eb70-453c-af5b-a10d1096f0b5",
  ];

  const newLine = () => {
    const line = newBudgetLine();
    const withOneExtraLine = state.budgetForEdit;
    withOneExtraLine.push(line);
    const lastIndex = withOneExtraLine.length - 1;
    dispatcher({ type: "setBudgetForEdit", payload: withOneExtraLine });
    dispatcher({ type: "setBudgetEditIndex", payload: lastIndex });
  };

  const historyObject = {
    type: "changes",
    userId: userInfo.userId,
    userName: userInfo.userDetails,
    change: "Budget updated",
  };

  const setModalClosed = () => {
    dispatcher({ type: "setModal", payload: "" });
  };

  const handleCancelButton = () => {
    setModalClosed();
  };

  const saveBudget = async (status, budget) => {
    const project = state.projectForEdit;
    if (status) {
      historyObject.change = "Budget updated. Status: " + status;
    }
    if (budget) {
      project.budget = sortedBudgetLines(budget);
    } else {
      project.budget = sortedBudgetLines(state.budgetForEdit);
    }
    project.history.push(historyObject);
    // sendNotification
    saveProject({ ...project, sendNotification: status });
    props.dispatch({ type: "updateProject", payload: project });
    console.log("===>> BUDGET SAVED ", project);
  };

  return (
    <Modal
      closeOnDimmerClick={false}
      closeIcon
      open={state.modal === "budgetModal"}
      onClose={setModalClosed}
    >
      <Header icon="dollar" content="Budget" />
      <Modal.Header>
        {projectId} - {state.projectForEdit.projectName}
      </Modal.Header>
      <Modal.Content>
        {state.budgetForEdit.length !== 0 && (
          <p>
            <em>* are mandatory fields</em>
          </p>
        )}
        {state.budgetForEdit.map((budgetLine, i) => (
          <BudgetLine
            line={budgetLine}
            index={i}
            key={i}
            canApprove={canUserApprove(
              userInfo.userId,
              projectId,
              ttoApprovers,
              innovationApprovers,
              merkantileApprovers
            )}
            canApproveERP={approversERP.includes(userInfo.userId)}
            saveBudget={saveBudget}
          />
        ))}
        <Button onClick={() => newLine()}>
          <Icon name="dollar" />
          New budget line
        </Button>
      </Modal.Content>
      <Modal.Actions>
        {/* <Button onClick={() => handleSubmitButton()}>
          <Icon name="save" />
          Save changes
        </Button> */}
        <Button onClick={() => handleCancelButton()}>
          <Icon name="cancel" />
          Close
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

export default BudgetModal;
